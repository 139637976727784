import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import loadable from '@loadable/component';

import { getLayoutHomepageSettings } from 'common/selectors/theme';
import { getLastSeenProductsSelector } from 'common/selectors/page';
import { fetchLastSeenProducts } from 'common/actions/PageActions';

const Column = loadable(() => import('common/components/styleguide/grid/Column'));
const Row = loadable(() => import('common/components/styleguide/grid/Row'));
const Container = loadable(() => import('common/components/styleguide/grid/Container'));

import Banners from 'common/components/home/banners/_default';

import { Block, BlockSecondary } from 'common/components/styleguide/grid';
import { Headline3 } from 'common/components/styleguide/Headline';

const NewProducts = loadable(() => import('common/blocks/products/new'));
const NewArrivals = loadable(() => import('common/components/home/newarrivals'));
const FavoriteProducts = loadable(() => import('common/components/home/favorites'));
const FooterProducts = loadable(() => import('common/components/home/FooterProducts'), { ssr: false });

const Newsletter = loadable(() =>
  import('common/blocks/functional/newsletter'),
  { ssr: false }
);

import { capfl } from 'common/utils';

import SS from 'common/pages/_style/index';

import CategoriesMenu from 'common/components/home/categories/foodfactory';

class Home extends PureComponent {
  componentDidMount() {
    const { setLastSeenProducts } = this.props;
    setLastSeenProducts();
  }
  render() {
    const { t, gotLastSeen, homepageSettings } = this.props;
    return (
      <Fragment>
        <Container>
          <Row>
            <Column size={{ xs: 12, md: 3, lg: 2 }}>
              <CategoriesMenu />
            </Column>
            <Column size={{ xs: 12, md: 9, lg: 10 }}>
              {_.get(homepageSettings, 'isBannersEnabled', true) ? <Banners /> : null}
              {_.get(homepageSettings, 'isProductsNewsEnabled', true) ? (
                <Container>
                  <Block marginConfig={{}}>
                    <NewProducts
                      headline={<Headline3>{capfl(t('new arrivals'))}</Headline3>}
                    />
                  </Block>
                </Container>
              ) : null}
              {_.get(homepageSettings, 'isProductsFavouriteEnabled', true) ? (
                <Container>
                  <Block marginConfig={{}}>
                    <FavoriteProducts />
                  </Block>
                </Container>
              ) : null}
              {_.get(homepageSettings, 'isProductsLatestEnabled', true) ? (
                <BlockSecondary marginConfig={{}}>
                  <Container>
                    <Headline3>{capfl(t('latests products'))}</Headline3>
                    <NewArrivals />
                  </Container>
                </BlockSecondary>
              ) : null}
              {_.get(homepageSettings, 'isProductsLastSeenEnabled', true) &&
              gotLastSeen ? (
                <SS.SectionFooterProducts>
                  <Container>
                    <Row>
                      <Column size={{ xs: 12 }}>
                        <SS.SectionTitle>{capfl(t('last seen'))}</SS.SectionTitle>
                      </Column>
                    </Row>
                    <Row>
                      <Column size={{ xs: 12 }}>
                        <SS.SectionContent>
                          <FooterProducts />
                        </SS.SectionContent>
                      </Column>
                    </Row>
                  </Container>
                </SS.SectionFooterProducts>
              ) : (
                ''
              )}
              {_.get(homepageSettings, 'isNewsletterEnabled', true) ? (
                <Newsletter />
              ) : null}
            </Column>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getLastSeenProducts = getLastSeenProductsSelector();
  const homepageSettings = getLayoutHomepageSettings();
  return (state, props) => {
    return {
      gotLastSeen: !_.isEmpty(getLastSeenProducts(state, props)),
      homepageSettings: homepageSettings(state, props)
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLastSeenProducts: () => {
      dispatch(fetchLastSeenProducts());
    }
  };
};

const HomeConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Home);

export default withTranslation()(HomeConnected);
