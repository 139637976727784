import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import RouteLink from 'common/components/_base/RouteLink';
import Icon from 'common/components/styleguide/Icon';
import List from 'common/components/styleguide/List';
import { getMenuSelector } from 'common/selectors/menu';
import { getActiveRouteSelector } from 'common/selectors';


const StyledListItem = styled.li`
  border-bottom: ${({ theme }) => _.get(theme, 'border.default.width')} ${({ theme }) => _.get(theme, 'border.default.style')} ${({ theme }) => _.get(theme, 'border.default.color')};
  &:last-child {
    border-bottom: none;
  }
`;

const StyledNav = styled.nav`
  flex: 1;
  background: ${({ theme }) => _.get(theme, 'page.backgroundSecondary')};
  // border: ${({ theme }) => _.get(theme, 'border.default.width')} ${({ theme }) => _.get(theme, 'border.default.style')} ${({ theme }) => _.get(theme, 'border.default.color')};
  padding: 8px;
`;

const StyledListItemChildren = styled(StyledListItem)`
  padding-left: 20px;
  &:first-child {
    border-top: ${({ theme }) => _.get(theme, 'border.default.width')} ${({ theme }) => _.get(theme, 'border.default.style')} ${({ theme }) => _.get(theme, 'border.default.color')};
  }
`;

const StyledChildrenList = styled(List)`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
`;

class CategoriesMenuItem extends PureComponent {
  render() {
    const { data, route } = this.props;
    const actualPage = _.get(route, 'matchParams.id', '');
    let subMenuIsHidden = _.find(data.children, (item) => { return item.urlName == actualPage }) ? false : true;
    if (subMenuIsHidden) {
      subMenuIsHidden = data.urlName == actualPage ? false : true;
    }
    return (
      <StyledListItem>
        <RouteLink
          route="category"
          params={{ id: data.urlName }}
          isActive={data.urlName == actualPage}
          linkType={'text'}
          customStyle={'padding: 7px; font-size: 0.9em; display: flex; justify-content: space-between;'}
        >
          {data.name}
          {!_.isEmpty(data.children) ? (
            <Fragment>
              {subMenuIsHidden ? (
                <Icon className="nc-ic_keyboard_arrow_right_24px" />
              ) : (
                <Icon className="nc-ic_keyboard_arrow_down_24px" />
              )}
            </Fragment>
          ) : null}
        </RouteLink>
        {!_.isEmpty(data.children) ? (
          <StyledChildrenList isHidden={subMenuIsHidden} reset>
            {_.map(data.children, children => (
              <StyledListItemChildren
                route="category"
                params={{ id: children.urlName }}
                key={'smallnav-item- ' + data.id + '-children-' + children.id}
              >
                <RouteLink
                  route="category"
                  params={{ id: children.urlName }}
                  isActive={children.urlName == actualPage}
                  linkType={'text'}
                  customStyle={'padding: 7px; font-size: 0.8em; display: flex; justify-content: space-between;'}
                >
                  {children.name}
                  <Icon className="nc-ic_keyboard_arrow_right_24px" />
                </RouteLink>
              </StyledListItemChildren>
            ))}
          </StyledChildrenList>
        ) : (
          ''
        )}
      </StyledListItem>
    );
  }
}

class CategoriesMenu extends PureComponent {
  render() {
    const { menu, route, className } = this.props;
    if (!_.isEmpty(menu)) {
      return (
        <StyledNav className={className}>
          <List reset>
            {_.map(menu, item => {
              return (
                <CategoriesMenuItem
                  data={item}
                  key={'smallnav-item-' + item.id}
                  route={route}
                />
              );
            })}
          </List>
        </StyledNav>
      );
    }
    return null;
  }
}

const makeMapStateToProps = () => {
  const getMenu = getMenuSelector();
  const getActiveRoute = getActiveRouteSelector();
  return (state, props) => {
    return { menu: getMenu(state, props), route: getActiveRoute(state, props) };
  };
};

const CategoriesMenuConnected = connect(makeMapStateToProps)(CategoriesMenu);

export default CategoriesMenuConnected;
