import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  BANNERS_TYPE_CUSTOMBANNER_1,
  BANNERS_TYPE_HOMEPAGE
} from 'common/constants/SiteConstants';

import { getBannersSelector } from 'common/selectors/page';

import loadable from '@loadable/component';
const Banners = loadable(() => import('common/blocks/banners'));

class HomeBanners extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { banners, useContainer } = this.props;
    if (!_.isEmpty(banners)) {
      const groupedBanners = _.groupBy(banners, 'type');
      return (
        <Banners
          banners={groupedBanners[BANNERS_TYPE_HOMEPAGE]}
          bannersAdditional1={groupedBanners[BANNERS_TYPE_CUSTOMBANNER_1]}
          position={'BannerHomepageMainVariant'}
          useContainer={useContainer}
        />
      );
    }
    return null;
  }
}

const makeMapStateToProps = () => {
  const getBanners = getBannersSelector();
  return (state, props) => {
    return { banners: getBanners(state, props) };
  };
};

export default connect(makeMapStateToProps)(HomeBanners);
